<template>
  <div id="scene-container"></div>
</template>

<script>
import * as THREE from 'three';

export default {
  name: 'HelloWorld',
  mounted() {
    // 創建場景
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    
    // 創建渲染器
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.getElementById('scene-container').appendChild(renderer.domElement);

    // 加載字體並創建 3D "E" 字母
    const loader = new THREE.FontLoader();
    loader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', function (font) {
      const geometry = new THREE.TextGeometry('E', {
        font: font,
        size: 5,
        height: 2,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 0.1,
        bevelSize: 0.1,
        bevelSegments: 5
      });
      const material = new THREE.MeshBasicMaterial({ color: 0xffffff });
      const mesh = new THREE.Mesh(geometry, material);
      scene.add(mesh);

      // 設置字母位置
      mesh.position.set(-2, 0, -10);

      // 動畫函數
      function animate() {
        requestAnimationFrame(animate);
        mesh.rotation.x += 0.01;
        mesh.rotation.y += 0.01;
        renderer.render(scene, camera);
      }
      animate();
    });

    // 設置相機位置
    camera.position.z = 20;
  }
};
</script>

<style>
#scene-container {
  width: 100vw;
  height: 100vh;
  background-color: black;
}
</style>
